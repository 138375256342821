input[type="button"] {
    padding: 5px 15px;
    outline: none;
    border: 1px solid #c8a838;
    color: #c8a838;
    background: #fff;
    border-radius: 8px;
    margin-bottom: 20px;
}
h1 {
    color: #c8a838;
    text-decoration: underline;
    text-align: center;
}
.grid p {
    padding: 0 15px;
    text-align: justify;
}
    .grid p .name {
        color: #c8a838;
        width: 65px;
        display: inline-block;
    }