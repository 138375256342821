.grid {
    display: inline-block;
    width: 100%;
    text-align: center;
    margin: auto;
    margin-bottom: 50px;
    box-shadow: 5px 4px 7px 4px rgb(43 43 49 / 13%);
    padding-top: 15px;
    padding-bottom: 15px;
}
    .grid img {
        padding-bottom: 15px;
        object-fit: cover;
        width: 90%
    }
input[type="text"],input[type="email"],input[type="number"],input[type="submit"], .fetch {
    width: 200px;
    padding: 5px 15px;
    height: 35px;
    margin: 10px;
}
.nav.nav-pills .nav-item{
    margin: 0 20px;
}